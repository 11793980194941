import MessageBoxOptions from "./MessageBoxOptions";

export interface MessageBoxState {
  messageBox: MessageBoxOptions;
}

export enum MessageBoxStateActions {
  MESSAGE_BOX = "MESSAGE_BOX",
}

export interface MessageBoxStateAction {
  type: MessageBoxStateActions;
  payload?: Partial<MessageBoxOptions>;
}

export const messageBoxStateReducer = (
  state: MessageBoxState,
  action: MessageBoxStateAction
) => {
  const { type } = action;
  switch (type) {
    case MessageBoxStateActions.MESSAGE_BOX:
      return setMessageBox(state, action);

    default:
      return state;
  }
};

const setMessageBox = (
  state: MessageBoxState,
  action: MessageBoxStateAction
) => {
  const { payload } = action;
  const msgBoxOpts = payload as Partial<MessageBoxOptions>;
  if (!msgBoxOpts) return state;

  const ustate = {
    ...state,
  };

  let msgBox = {
    ...ustate.messageBox,
    ...msgBoxOpts,
  };
  ustate.messageBox = msgBox;
  return ustate;
};
