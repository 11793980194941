import hash from 'object-hash'
export const getHashCode = (data: any) => {
  return hash(data)
}

const getHash = (value: string | number | boolean) => {
  let hash1 = 5381
  let hash2 = hash1
  const data = value.toString()

  if (data === '') return 0

  for (let i = 0; i < data.length; ++i) {
    hash1 = ((hash1 << 5) + hash1) ^ data.charCodeAt(i)
    hash2 = ((hash2 << 5) + hash2) ^ data.charCodeAt(i)
  }
  return hash1 + hash2 * 1566083941
}

export const reduceReducers = (...reducers: any[]) => (
  state: any,
  action: any
) => reducers.reduce((acc, nextReducer) => nextReducer(acc, action), state)
