import { Node } from './interfaces/Node'

export const getNodeDefaults = () => {
  const nodeObject: Node = {
    guid: '',
    isEnabled: true,
    name: '',
    description: '',
    config: {},
    status: {
      isValid: false,
      errors: { 'en-us': ['test'] }
    }
  }
  return nodeObject
}
