import {
  FormControlLabel,
  Switch,
  makeStyles,
  createStyles,
  Theme,
  TextField
} from '@material-ui/core'
import { NodeContextType, NodeContext, NodeStateActions } from './NodeState'
import { EditableTextField } from './EditableTextField'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
      '&:last-child': {
        'padding-bottom': 0
      }
    },
    formControlLabelRoot: {
      marginLeft: '-15px',
    },
    label: {
      'margin-left': '8px'
    },
    outlinedRoot: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black'
        }
      },
      '& .MuiInputBase-root': {
        height: '36.5px'
      }
    },
    switchRoot: {
      width: 39,
      height: 20,
      padding: 0,
      margin: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      '& .MuiIconButton-label': {
        height: '16px'
      }
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(18px)',
        '& + $track': {
          opacity: 0.5,
          border: 'none'
        }
      },
      '&$focusVisible $thumb': {
        border: '6px solid #fff'
      }
    },
    switchContainer: {
      marginBottom: '0.7rem'
    },
    thumb: {
      width: 18,
      height: 18,
      backgroundColor: '#5f3fa5'
    },
    track: {
      height: 12,
      marginLeft: 8,
      marginRight: 8,
      borderRadius: 20 / 2,
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    }
  })
)

export const EnabledSwitch = (props: { i18n?: any }) => {
  const { state, dispatch } = useContext<NodeContextType>(NodeContext)
  const { t } = useTranslation('translation', { i18n: props.i18n })
  const classes = useStyles()

  const handleEnableChange = () => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        isEnabled: !state.node.isEnabled
      }
    })
  }

  return (
    <FormControlLabel
      className={classes.formControlLabelRoot}
      value='start'
      control={
        <Switch
          checked={state.node.isEnabled}
          onChange={handleEnableChange}
          color='secondary'
          classes={{
            root: classes.switchRoot,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track
          }}
        />
      }
      label={state.node.isEnabled ? t('Enabled') : t('Disabled')}
      labelPlacement='end'
    />
  )
}

export const EnableNameDescriptionFields = (props: {
  i18n?: any
  hideEnabled?: boolean
}) => {
  const { hideEnabled } = props
  const { state, dispatch } = useContext<NodeContextType>(NodeContext)
  const { t } = useTranslation('translation', { i18n: props.i18n })

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        name: e.target.value.replace(/{\s*([^}]*(?:}(?!})[^}]*)*)}/g, '')
      }
    })
  }
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        description: e.target.value.replace(/{\s*([^}]*(?:}(?!})[^}]*)*)}/g, '')
      }
    })
  }

  const classes = useStyles()
  return (
    <div>
      {hideEnabled ? null : (
        <div className={classes.switchContainer}>
            <EnabledSwitch i18n={props.i18n} />
        </div>
      )}
        <div>
          <div>
            <TextField
              key={t('Name')}
              label={t('Name') || 'Name'}
              InputLabelProps={{
                shrink: true,
                style: { color: 'black', fontSize: '.875rem' }
              }}
              variant='outlined'
              fullWidth
              className={`${classes.outlinedRoot} mb-5`}
              value={state.node.name}
              onChange={handleNameChange}
              inputProps={{ maxLength: '200' }}
              style={{ minWidth: '150px' }}
            />
          </div>
          <div>
            <TextField
              label={t('Description') || 'Description'}
              InputLabelProps={{
                shrink: true,
                style: { color: 'black', fontSize: '.875rem' }
              }}
              variant='outlined'
              fullWidth
              placeholder={t('Description here...') || 'Description here...'}
              className={`${classes.outlinedRoot} mb-5`}
              value={state.node.description}
              onChange={handleDescriptionChange}
              inputProps={{ maxLength: '200' }}
              style={{ minWidth: '150px' }}
            />
          </div>
        </div>
    </div>
  )
}
