import React, { useReducer } from 'react'
import {
  MessageBoxState,
  MessageBoxStateAction,
  messageBoxStateReducer
} from './MessageBoxStateUpdate'
import { getMessageBoxDefaults } from './MessageBoxDefaults'

const messageBoxInitialState: MessageBoxState = {
  messageBox: getMessageBoxDefaults()
}

export const MessageBoxContext = React.createContext<{
  state: MessageBoxState
  dispatch: (action: MessageBoxStateAction) => void
}>({
  state: messageBoxInitialState,
  dispatch: () => {}
})

export const MessageBoxContextProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(
    messageBoxStateReducer,
    messageBoxInitialState
  )
  const contextValue = { state, dispatch }
  return (
    <MessageBoxContext.Provider value={contextValue}>
      {props.children}
    </MessageBoxContext.Provider>
  )
}

export default MessageBoxContextProvider
