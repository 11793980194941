import React, { useContext } from 'react'
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  SlideProps,
  Typography
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import HelpIcon from '@material-ui/icons/Help'
import CloseIcon from '@material-ui/icons/Close'

import { MessageBoxButton } from './MessageBoxButton'
import { MessageBoxType } from './MessageBoxType'
import { MessageBoxImageType } from './MessageBoxImageType'
import { MessageBoxContext } from './MessageBoxContext'
import { MessageBoxStateActions } from './MessageBoxStateUpdate'
import { getMessageBoxDefaults } from './MessageBoxDefaults'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    buttonSmall: {
      minWidth: '5rem',
      '&:not(:first-child)': {
        marginLeft: '20px'
      }
    }
  })
)

const useStyles2 = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      right: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      padding: 0,
      fontSize: '1.6rem'
    }
  })
)

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />
})

export const CustomMessageBox = (props: { i18n?: any }) => {
  const { state, dispatch } = useContext(MessageBoxContext)
  const {
    boxType,
    imageType,
    title,
    message,
    open: isMsgBoxOpen
  } = state.messageBox
  const classes = useStyles()
  const classes2 = useStyles2()
  const { t } = useTranslation('translation', { i18n: props.i18n })
  let buttons: MessageBoxButton[] = [
    {
      caption: t('Ok') || 'Ok',
      isDefault: true
    }
  ]

  switch (boxType) {
    case MessageBoxType.YesNo:
      buttons[0].caption = t('Yes') || 'Yes'
      buttons.push({
        caption: t('No') || 'No',
        isDefault: false
      })
      break

    case MessageBoxType.NoYes:
      buttons[0].caption = t('No') || 'No'
      buttons[0].isDefault = false
      buttons.push({
        caption: t('Yes') || 'Yes',
        isDefault: true
      })
      break

    case MessageBoxType.YesNoCancel:
      buttons[0].caption = t('Yes') || 'Yes'
      buttons.push({
        caption: t('No') || 'No',
        isDefault: false
      })
      buttons.push({
        caption: t('Cancel') || 'Cancel',
        isDefault: false
      })
      break

    case MessageBoxType.None:
      buttons = []
      break
  }

  if (
    state.messageBox.buttons &&
    state.messageBox.buttons.length === buttons.length
  ) {
    let hasDefault = false
    for (let i = 0; i < buttons.length; ++i) {
      buttons[i] = {
        ...buttons[i],
        ...state.messageBox.buttons[i]
      }

      // make sure there is only one default button.
      if (hasDefault) {
        buttons[i].isDefault = false
      } else {
        hasDefault = buttons[i].isDefault || false
      }
    }
  }

  const closeMessageBox = () => {
    if (boxType === MessageBoxType.None) return
    // send dispatch event to close the message box
    dispatch({
      type: MessageBoxStateActions.MESSAGE_BOX,
      payload: getMessageBoxDefaults()
    })
  }

  const handleDialogActions = (
    button: MessageBoxButton,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    closeMessageBox()
    if (button && button.callback) button.callback(event)
  }

  return (
    <div>
      <Dialog
        open={isMsgBoxOpen}
        TransitionComponent={Transition}
        onClose={closeMessageBox}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle style={{ fontWeight: 700 }}>
          {boxType !== MessageBoxType.None && (
            <IconButton
              classes={classes2}
              onClick={closeMessageBox}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography noWrap className={`text-ellipsis ${boxType !== MessageBoxType.None && 'mr-4'}`}>{title || 'Dispatcher Workflow Designer'}</Typography>
        </DialogTitle>
        <DialogContent className='flex gap-4 items-center'>
          <IconRender imageType={imageType} />

          <DialogContentText id='alert-dialog-slide-description flex items-center'>
            {message}
          </DialogContentText>
        </DialogContent>
        {buttons?.length > 0 && (
          <DialogActions
            style={{
              paddingLeft: '23px',
              paddingRight: '23px',
              paddingBottom: '23px'
            }}
          >
            {buttons.map((item, index) => (
              <Button
                variant={item.isDefault ? 'contained' : 'outlined'}
                color='primary'
                className={classes.buttonSmall}
                onClick={(event) => handleDialogActions(item, event)}
                key={index}
              >
                {item.caption}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

interface IconProps {
  imageType?: MessageBoxImageType
}
const IconRender = (props: IconProps) => {
  const getIcon = () => {
    switch (props.imageType) {
      case MessageBoxImageType.Error:
        return (
          <ErrorIcon
            className='place-self-start'
            color='primary'
            fontSize='large'
          />
        )
      case MessageBoxImageType.Information:
        return (
          <InfoIcon
            className='place-self-start'
            color='primary'
            fontSize='large'
          />
        )
      case MessageBoxImageType.Question:
        return (
          <HelpIcon
            className='place-self-start'
            color='primary'
            fontSize='large'
          />
        )
      case MessageBoxImageType.Warning:
        return (
          <WarningIcon
            className='place-self-start'
            color='primary'
            fontSize='large'
          />
        )
    }
    return null
  }
  return getIcon()
}

export default CustomMessageBox
