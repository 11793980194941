import { MessageBoxImageType } from "./MessageBoxImageType";
import MessageBoxOptions from "./MessageBoxOptions";
import { MessageBoxType } from "./MessageBoxType";

export const getMessageBoxDefaults = () => {
  const obj: MessageBoxOptions = {
    boxType: MessageBoxType.Ok,
    imageType: MessageBoxImageType.Information,
    open: false,
    title: "",
    message: "",
  };
  return obj;
};
