import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  SvgIcon
} from '@material-ui/core'

import HelpIcon from '@material-ui/icons/HelpOutline'
import { NodeContext, NodeContextType } from './NodeContext'
import { getHashCode } from '../../utils/HashCode'
import { MessageBoxType } from '../CustomMessageBox/MessageBoxType'
import { MessageBoxImageType } from '../CustomMessageBox/MessageBoxImageType'
import { MessageBoxContext } from '../CustomMessageBox/MessageBoxContext'
import { MessageBoxStateActions } from '../CustomMessageBox/MessageBoxStateUpdate'
import StratusDesigner from '@dispatcher-stratus/stratusjs'
import { ReactComponent as MetadataIcon } from '../../assets/meta-icon.svg'
import { useLocation } from 'react-router-dom'
import { NodeState, NodeStateActions } from './NodeStateUpdate'
import { useTranslation } from 'react-i18next'

declare global {
  interface Window {
    stratusDesigner: typeof StratusDesigner
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonNormal: {
      minWidth: '7rem'
    },
    buttonSmall: {
      minWidth: '5rem'
    }
  })
)

export const NodeDialogActions =
  NodeDialogActionsComponent as typeof NodeDialogActionsComponent

interface Props {
  helpPath?: string
  showMetadataButton?: boolean
  afterSave?(state?: NodeState<any>): Promise<boolean>
  beforeSave?(state?: NodeState<any>): Promise<boolean | object>
  onIndexFormNodeUpdate?: (data: any) => void
  updateHashAfterSave?: boolean
  i18n?: any
  disableSave?: boolean
  requiredValues?: Array<{ name: string; value: any }>
}

function NodeDialogActionsComponent (props: Props) {
  const { state, stratusDesigner, dispatch } =
    useContext<NodeContextType>(NodeContext)
  const { dispatch: showMessage } = useContext(MessageBoxContext)
  const classes = useStyles()
  const [hasChanges, setHasChanges] = useState(false)
  const { search } = useLocation()
  const { t } = useTranslation('translation', { i18n: props.i18n })

  useEffect(() => {
    if (!window.stratusDesigner) {
      window.stratusDesigner = stratusDesigner
    }
    stratusDesigner.options['onClosing'] = onWindowClosing
    stratusDesigner.options['onIndexFormNodeUpdate'] = onIndexFormNodeUpdate
    setHasChanges(state.hash !== getHashCode(state.node))
  }, [JSON.stringify(state.node)])

  async function saveNodeConfig (event: any) {
    const sessionId = search
      ? new URLSearchParams(search as any).get('sid') || ''
      : ''
    if (event) event.preventDefault()
    if (
      !state.node.name ||
      (typeof state.node.name === 'string' &&
        state.node.name.trim().length === 0)
    ) {
      showMessage({
        type: MessageBoxStateActions.MESSAGE_BOX,
        payload: {
          imageType: MessageBoxImageType.Error,
          open: true,
          message: t('Node name is required.')
        }
      })
      return
    }
    const missingValue = props.requiredValues?.find(reqValue =>
      !reqValue.value || (typeof reqValue.value === 'string' && reqValue.value.trim().length === 0)
    );
  
    if (missingValue) {
      showMessage({
        type: MessageBoxStateActions.MESSAGE_BOX,
        payload: {
          imageType: MessageBoxImageType.Error,
          open: true,
          message: t(`${missingValue.name} is missing.`)
        }
      });
      return;
    }
    let alteredState: any
    if (typeof props.beforeSave === 'function') {
      const result = await props.beforeSave(state)
      if (result === false) {
        return
      } else if (typeof result === 'object') alteredState = result
    }
    if (sessionId) {
      const payload = {
        token: state.token,
        nodeId: state.nodeid,
        workflowId: state.wfid,
        sessionId: sessionId,
        sessionState: alteredState?.node || state.node
      }
      // Need to save session
      const url = `/api/node/sessions/${state.wfid}/${sessionId}`
      // TODO error catching
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (res) => {
          if (res.status < 300) {
            if (typeof props.afterSave === 'function')
              await props.afterSave(state)
            if (
              props.updateHashAfterSave ||
              props.updateHashAfterSave === undefined
            ) {
              dispatch({
                type: NodeStateActions.UPDATE_HASH,
                payload: {}
              })
            }
            return res
          }
          throw res
        })
        .then((res) => res.json())
        .then((res) => {
          showMessage({
            type: MessageBoxStateActions.MESSAGE_BOX,
            payload: {
              imageType: MessageBoxImageType.Information,
              open: true,
              message: t('Sucessfully saved session.')
            }
          })
          return res
        })
        .catch((res) => {
          showMessage({
            type: MessageBoxStateActions.MESSAGE_BOX,
            payload: {
              imageType: MessageBoxImageType.Error,
              open: true,
              message: t('Failed to save session.')
            }
          })
          console.log('updateSessionNodeConfig failed', { payload, res })
          return null
        })
    } else {
      const payload = {
        wfid: state.wfid,
        nodeid: state.nodeid,
        data: alteredState?.node || state.node
      }
      // Need to save session
      const response = await stratusDesigner.updateNodeConfig(payload)
      if (response?.status === 204 || response?.status === 200) {
        if (typeof props.afterSave === 'function') await props.afterSave(state)
        if (
          props.updateHashAfterSave ||
          props.updateHashAfterSave === undefined
        ) {
          dispatch({
            type: NodeStateActions.UPDATE_HASH,
            payload: {}
          })
        }
        await closeNodeWindow()
      } else console.log('updateNodeConfig failed', { payload, response })
    }
  }

  async function closeNodeWindow () {
    const payload = {
      wfid: state.wfid,
      nodeid: state.nodeid
    }
    const response = await stratusDesigner.closeWindow(payload)
    if (!(response.status === 204 || response.status === 200)) {
      console.log('closeWindow failed', { response })
    }
  }
  async function handleMetadataClick () {
    const payload = {
      wfid: state.wfid,
      nodeid: state.nodeid
    }
    const response = await stratusDesigner.launchMetadataBrowser(payload)
    if (!(response.status === 204 || response.status === 200)) {
      console.log('launchMetadataBrowser failed', { response })
    }
  }

  async function handleHelpClick() {
    if (!props.helpPath) return
    const payload = {
      wfid: state.wfid,
      nodeid: state.nodeid,
      data: {
        path: props.helpPath
      }
    }
    const response = await stratusDesigner.openHelp(payload)
    if (!(response.status === 204 || response.status === 200)) {
      console.log('openHelp failed', { response })
    }
  }

  const onIndexFormNodeUpdate = (data: any) => {
    if (typeof props.onIndexFormNodeUpdate === 'function') {
      props.onIndexFormNodeUpdate(data)
    }
  }

  const onWindowClosing = () => {
    setHasChanges((hasChanges) => {
      if (hasChanges) {
        showConfirmationDialog()
        return true
      } else {
        closeNodeWindow()
        return false
      }
    })
  }

  const showConfirmationDialog = () => {
    showMessage({
      type: MessageBoxStateActions.MESSAGE_BOX,
      payload: {
        boxType: MessageBoxType.NoYes,
        imageType: MessageBoxImageType.None,
        open: true,
        message: t('Would you like to save changes to this node?'),
        buttons: [
          {
            callback: closeNodeWindow
          },
          {
            callback: saveNodeConfig
          }
        ]
      }
    })
  }

  return (
    <div
      className='flex w-full py-4 pl-4 pr-4 justify-between flex-grow-0'
      style={{ backgroundColor: '#DBDDE6' }}
    >
      <div className='flex gap-4 items-center'>
        <Button
          variant='outlined'
          color='primary'
          className={classes.buttonNormal}
          startIcon={<HelpIcon />}
          onClick={handleHelpClick}
        >
          {t('Help')}
        </Button>
        {props.showMetadataButton && (
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonNormal}
            onClick={handleMetadataClick}
            startIcon={
              <SvgIcon>
                <MetadataIcon />
              </SvgIcon>
            }
          >
            {t('Metadata')}
          </Button>
        )}
      </div>
      <div className='flex gap-4 items-center'>
        <Button
          variant='outlined'
          color='primary'
          className={classes.buttonNormal}
          onClick={closeNodeWindow}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonNormal}
          onClick={saveNodeConfig}
          disabled={props.disableSave}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  )
}

export default NodeDialogActions
