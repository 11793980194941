import { Node } from './interfaces/Node'
import { NodeBase } from './interfaces/NodeBase'
import { getHashCode } from '../../utils/HashCode'
import { NodeConfig } from './interfaces/NodeConfig'
import { NodeStatus } from './interfaces/NodeStatus'
import jwt_decode from 'jwt-decode'

export interface NodeState<T = any> {
  node: Node<T>
  hash: string | number
  wfid: string
  nodeid: string
  username?: string
  tenant?: {
    id: string
    name: string
    domain: string
  }
  token: string
  configIsLoaded: boolean
  sessionid: string
}

export enum NodeStateActions {
  SET_NODEID_WFID_TOKEN = 'SET_NODEID_WFID_TOKEN',
  CONFIG_IS_LOADED = 'CONFIG_IS_LOADED',
  SET_NODE_COMMON_PROPERTY = 'SET_NODE_COMMON_PROPERTY',
  SET_NODE = 'SET_NODE',
  SET_NODE_CONFIG = 'SET_NODE_CONFIG',
  SET_NODE_CONFIG_NO_HASH = 'SET_NODE_CONFIG_NO_HASH',
  UPDATE_NODE_CONFIG = 'UPDATE_NODE_CONFIG',
  UPDATE_NODE_CONFIG_AND_HASH = 'UPDATE_NODE_CONFIG_AND_HASH',
  UPDATE_HASH = 'UPDATE_HASH',
  SET_NODE_STATUS = 'SET_NODE_STATUS'
}

export interface NodeStateAction<T = any> {
  type: NodeStateActions
  payload?:
    | Partial<Node<T>>
    | Partial<NodeBase>
    | Partial<NodeConfig<T>>
    | Partial<NodeState<T>>
    | Partial<NodeStatus>
}

export const nodeStateReducer = (state: NodeState, action: NodeStateAction) => {
  const { type } = action
  let newState: any

  switch (type) {
    case NodeStateActions.SET_NODEID_WFID_TOKEN:
      return {
        ...state,
        ...(action.payload as Pick<
          NodeState,
          'nodeid' | 'wfid' | 'token' | 'sessionid' | 'tenant' | 'username'
        >),
        username: (action.payload as any)?.token
          ? jwt_decode<any>((action.payload as any)?.token)?.username
          : undefined,
        tenant: (action.payload as any)?.token
          ? jwt_decode<any>((action.payload as any)?.token)?.tenant
          : undefined
      }

    case NodeStateActions.CONFIG_IS_LOADED:
      return {
        ...state,
        configIsLoaded: true
      }

    case NodeStateActions.SET_NODE_COMMON_PROPERTY:
      return {
        ...state,
        node: { ...state.node, ...action.payload }
      }

    case NodeStateActions.SET_NODE:
      newState = {
        ...state,
        node: {
          ...state.node,
          ...action.payload,
          config: { ...state.node.config, ...(action.payload as Node)?.config }
        }
      }
      return { ...newState, hash: getHashCode(newState.node) }

    case NodeStateActions.SET_NODE_CONFIG_NO_HASH:
      newState = {
        ...state,
        node: {
          ...state.node,
          ...action.payload,
          config: { ...state.node.config, ...(action.payload as Node)?.config }
        }
      }
      return newState

    case NodeStateActions.SET_NODE_CONFIG:
      return {
        ...state,
        node: {
          ...state.node,
          config: {
            ...(action.payload as Partial<NodeConfig>)
          }
        }
      }

    case NodeStateActions.SET_NODE_STATUS:
      return {
        ...state,
        node: {
          ...state.node,
          status: {
            ...(action.payload as Partial<NodeStatus>)
          }
        }
      }

    case NodeStateActions.UPDATE_NODE_CONFIG:
      newState = {
        ...state,
        node: {
          ...state.node,
          config: {
            ...state.node.config,
            ...(action.payload as Partial<NodeConfig>)
          }
        }
      }
      return {
        ...newState
      }

    case NodeStateActions.UPDATE_NODE_CONFIG_AND_HASH:
      newState = {
        ...state,
        node: {
          ...state.node,
          config: {
            ...state.node.config,
            ...(action.payload as Partial<NodeConfig>)
          }
        },
        hash: getHashCode({
          ...state.node,
          config: {
            ...state.node.config,
            ...(action.payload as Partial<NodeConfig>)
          }
        })
      }
      return {
        ...newState
      }

    case NodeStateActions.UPDATE_HASH:
      return {
        ...state,
        hash: getHashCode(state.node)
      }

    default:
      return state
  }
}

const setNodeCommonProperties = (state: NodeState, action: NodeStateAction) => {
  const { payload } = action
  if (!payload) return state

  const nodeProps = payload as Partial<NodeBase>
  if (!nodeProps) return state

  let ustate = copyState(state)
  ustate.node = {
    ...ustate.node,
    ...nodeProps
  }
  return ustate
}

const setNode = (state: NodeState, action: NodeStateAction) => {
  const { payload } = action
  const nodeObj = payload as Partial<Node>
  if (!nodeObj) return state

  let ustate = copyState(state)
  ustate = setNodeCommonProperties(ustate, {
    type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
    payload: nodeObj as Partial<NodeBase>
  })

  ustate.hash = getHashCode(ustate.node)
  return ustate
}

const copyState = (state: NodeState) => {
  const node = {
    ...state.node
  }
  const ustate = {
    ...state
  }
  ustate.node = node
  return ustate
}
