import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  SvgIcon,
  Tooltip,
  TooltipProps
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { ChangeEvent, useRef, useState } from 'react'
import { ReactComponent as EditIcon } from '../assets/icon-edit-pencil.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        borderColor: 'rgba(0, 0, 0, 0)',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.light
        }
      },
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0)'
      },
      '&.Mui-focused': {
        '&:hover': {
          backgroundColor: theme.palette.background.paper
        },
        cursor: 'text',
        '& .MuiInputAdornment-root': {
          display: 'none',
          '& .MuiSvgIcon-root': {
            color: 'rgba(0, 0, 0, 0)'
          }
        }
      }
    },
    input: {
      textOverflow: 'ellipsis',
      '&:hover': {
        cursor: 'pointer',
        '&:focus': { cursor: 'text' }
      }
    }
  })
)

function ConditionalTooltip ({
  renderTooltip,
  children,
  ...props
}: TooltipProps & { renderTooltip: boolean }) {
  return renderTooltip ? <Tooltip {...props}>{children}</Tooltip> : children
}

export const EditableTextField = (props: OutlinedInputProps) => {
  const classes = useStyles()
  const inputEl = useRef<HTMLInputElement>(null)
  const [prevVal, setPrevVal] = useState('')
  const [focused, setFocused] = useState(false)

  return (
    <Tooltip
      title={!focused ? (props.value as string) || '' : ''}
      enterDelay={500}
      placement='bottom'
    >
      <OutlinedInput
        autoFocus={focused}
        classes={classes}
        endAdornment={
          <InputAdornment
            onClick={() => inputEl?.current?.focus()}
            position='end'
          >
            <SvgIcon fontSize='large' viewBox='-6 -6 24 24'>
              <EditIcon />
            </SvgIcon>
          </InputAdornment>
        }
        color='secondary'
        notched={false}
        inputRef={inputEl}
        onFocus={(e) => {
          setPrevVal(inputEl?.current?.value || '')
          setFocused(true)
          if (props !== undefined && typeof props.onFocus === 'function')
            props?.onFocus?.(e)
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter' && !props.multiline)
            inputEl?.current?.blur()
          if (
            event.key === 'Escape' ||
            event.key === 'Esc' ||
            event.keyCode === 27
          ) {
            const changeEvent = {
              target: {
                name: inputEl.current?.name,
                checked: inputEl.current?.checked,
                value: prevVal
              }
            } as ChangeEvent<HTMLInputElement>
            if (props !== undefined && typeof props.onChange === 'function')
              props.onChange?.(changeEvent)
            inputEl?.current?.blur()
          }
          if (props !== undefined && typeof props.onKeyDown === 'function')
            props.onKeyDown?.(event)
        }}
        onBlur={(event) => {
          setFocused(false)
          if (props !== undefined && typeof props.onBlur === 'function')
            props.onBlur?.(event)
        }}
        {...props}
        inputProps={{
          ...props?.inputProps,
          autocomplete: 'new-password',
          size: (props.value as string).length || 20
        }}
      />
    </Tooltip>
  )
}
